var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "notification-modal",
      attrs: {
        id: "notifications",
        size: "md",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      _c(
        "div",
        [
          _c("p", [
            _vm._v(
              "\n      We’re in the process of preparing for compliance season & we are missing pertinent information to complete your filing(s).\n    "
            ),
          ]),
          _c("p", [
            _vm._v(
              "\n      Please enter the missing information in the boxes provided and click save.\n    "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v("(Webfile Number):")]),
            _vm._v(
              " Begins with XT or FQ followed by 6 digits, and was mailed to you by the TX Comptroller. If you cannot locate this please contact TX Comptroller 512-463-4600\n    "
            ),
          ]),
          _c("p", [
            _c("b", [_vm._v("(Taxpayer Number):")]),
            _vm._v(" Search "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://mycpa.cpa.state.tx.us/coa/",
                  target: "_blank",
                },
              },
              [_vm._v("https://mycpa.cpa.state.tx.us/coa/")]
            ),
            _vm._v(" to locate the mailing address & taxpayer number.\n    "),
          ]),
          _vm._l(_vm.registrations, function (registration) {
            return _c(
              "div",
              { key: registration.id, staticClass: "registration-details" },
              [
                _c("p", [
                  _vm._v("Company Name: " + _vm._s(registration.legal_name)),
                ]),
                _vm._l(registration, function (value, key) {
                  return _c(
                    "div",
                    { key: key },
                    [
                      !["id", "legal_name"].includes(key.toLowerCase())
                        ? [
                            value
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.formatLabel(key)) +
                                      ": " +
                                      _vm._s(value)
                                  ),
                                ])
                              : _c("p", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.formatLabel(key)) +
                                      ":\n            "
                                  ),
                                  _c("input", {
                                    attrs: {
                                      id: registration.id,
                                      placeholder: _vm.formatLabel(key),
                                    },
                                    domProps: { value: registration[key] },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateRegistrations(
                                          registration.id,
                                          key,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.logRemindMeLaterResponse()
                },
              },
            },
            [_vm._v("\n      Remind me later\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn btn-primary text-nowrap",
              attrs: { type: "submit" },
              on: {
                click: function ($event) {
                  return _vm.saveHandler()
                },
              },
            },
            [_vm._v("\n      Save\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }