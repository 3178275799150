<template>
  <simple-notification-modal
    v-if="messagesContainQuote"
    :key="ephemeralMessage.id"
    :subject="ephemeralMessage"
    button-name="Take me there"
    @buttonHandler="clickHandlerForQuote"
  />
  <simple-notification-modal
    v-else-if="isShowEphemeralMessage"
    :key="ephemeralMessage.id"
    :subject="ephemeralMessage"
    @buttonHandler="clickHandler"
  />
  <simple-notification-modal
    v-else-if="knownBadAddresses"
    :key="knownBadAddressMessage.id"
    :subject="knownBadAddressMessage"
    button-name="Take me there"
    @buttonHandler="clickHandlerForKnownBadAddress"
  />
  <registration-info-notification-modal
    v-else-if="missingRegistrationInfo"
    :registrations="registrations"
  />
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import SimpleNotificationModal from '@/components/shared/SimpleNotificationModal'
import RegistrationInfoNotificationModal from '@/components/shared/RegistrationInfoNotificationModal'
import axiosClient from '../http'

export default {
  name: 'NotificationManager',
  components: { SimpleNotificationModal, RegistrationInfoNotificationModal },
  data() {
    return {
      registrations: [],
      loaded: false,
      knownBadAddressMessage: {
        account_id: '0k1',
        body: "We are showing we have an invalid address for you. " +
          "Please update your address information in your Account Settings.",
        id: '0k1',
      },
      isShowBadKnownAddress: false,
    }
  },
  computed: {
    ...mapGetters('ephemeralMessage', ['ephemeralMessages'],),
    ...mapGetters('account', ['knownBadAddresses']),
    ...mapGetters('stageline', ['accountCompanies']),
    isShowEphemeralMessage() {
      return this.loaded && typeof(this.ephemeralMessage ) === 'object'
    },
    ephemeralMessage() {
      return this.ephemeralMessages[0]
    },
    messagesContainQuote() {
      return this.ephemeralMessages && this.ephemeralMessages[0]?.message_type == 'quote-reminder'
    },
    missingRegistrationInfo() {
      return this.registrations.length
    },
  },


  async mounted() {
    await this.loadMissingRegistrations()
    await this.loadEphemeralMessages()
    await this.checkIfThereAreKnownBadAddresses()
    this.loaded = true
  },
  methods: {
    ...mapActions(
      'ephemeralMessage', ['loadEphemeralMessages', 'updateStatus'],
    ),
    checkIfThereAreKnownBadAddresses() {
      this.knownBadAddresses > 0 ? this.isShowBadKnownAddress = true : false
    },
    clickHandler() {
      this.updateStatus({ ephemeralMessageId: this.ephemeralMessage.id })
    },
    clickHandlerForKnownBadAddress() {
      this.$router.push({ name: 'accountSettings' })
    },
    clickHandlerForQuote() {
      this.clickHandler()
      this.$router.push({ name: 'documents' })
    },
    async loadMissingRegistrations() {
      const res = await axiosClient.get('/client/registrations/missing_texas_registrations')
      if (res.status === 200) {
        this.registrations = res.data.result
      }
    },
  },
}
</script>
