var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.messagesContainQuote
    ? _c("simple-notification-modal", {
        key: _vm.ephemeralMessage.id,
        attrs: {
          subject: _vm.ephemeralMessage,
          "button-name": "Take me there",
        },
        on: { buttonHandler: _vm.clickHandlerForQuote },
      })
    : _vm.isShowEphemeralMessage
    ? _c("simple-notification-modal", {
        key: _vm.ephemeralMessage.id,
        attrs: { subject: _vm.ephemeralMessage },
        on: { buttonHandler: _vm.clickHandler },
      })
    : _vm.knownBadAddresses
    ? _c("simple-notification-modal", {
        key: _vm.knownBadAddressMessage.id,
        attrs: {
          subject: _vm.knownBadAddressMessage,
          "button-name": "Take me there",
        },
        on: { buttonHandler: _vm.clickHandlerForKnownBadAddress },
      })
    : _vm.missingRegistrationInfo
    ? _c("registration-info-notification-modal", {
        attrs: { registrations: _vm.registrations },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }