var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "notification-modal",
      attrs: {
        id: "notifications",
        size: "md",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      _c(
        "h3",
        {
          staticClass: "text-center",
          staticStyle: { margin: "0", padding: "0" },
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _c("br"),
      _c("div", { staticClass: "mb-2 text-center" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.subjectBody) } }),
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-2",
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.hidePopup()
                },
              },
            },
            [_vm._v("\n      Remind me later\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn btn-primary text-nowrap",
              attrs: { type: "submit" },
              on: {
                click: function ($event) {
                  return _vm.clickHandler()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.buttonName) + "\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }