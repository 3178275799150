<template>
  <b-modal
    id="notifications"
    ref="notification-modal"
    size="md"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <h3 style="margin: 0; padding: 0" class="text-center">
      {{ title }}
    </h3>
    <br>
    <div class="mb-2 text-center">
      <div v-html="subjectBody" />
    </div>
    <br>
    <div class="text-center">
      <b-button variant="secondary" class="mr-2" @click="hidePopup()">
        Remind me later
      </b-button>
      <b-button class="btn btn-primary text-nowrap" type="submit" @click="clickHandler()">
        {{ buttonName }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
  import * as DOMPurify from 'dompurify'

  export default {
    name: 'SimpleNotificationModal',
    props: {
      title: {
          type: String,
          default: 'Important information',
      },
      buttonName: {
        type: String,
        default: 'I understand',
      },
      subject: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {}
    },
    computed: {
      subjectBody() {
        return DOMPurify.sanitize(this.subject.body)
      },
    },
    async mounted() {
      this.$refs['notification-modal'].show()
    },
    methods: {
      clickHandler(e) {
        this.$emit('buttonHandler', e)
        this.$refs['notification-modal'].hide()
      },
      hidePopup(){
        this.$refs['notification-modal'].hide()
      },
    },
  }
</script>
