<template>
  <b-modal
    id="notifications"
    ref="notification-modal"
    size="md"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <div>
      <p>
        We’re in the process of preparing for compliance season & we are missing pertinent information to complete your filing(s).
      </p>
      <p>
        Please enter the missing information in the boxes provided and click save.
      </p>
      <p>
        <b>(Webfile Number):</b> Begins with XT or FQ followed by 6 digits, and was mailed to you by the TX Comptroller. If you cannot locate this please contact TX Comptroller 512-463-4600
      </p>
      <p>
        <b>(Taxpayer Number):</b> Search <a href="https://mycpa.cpa.state.tx.us/coa/" target="_blank">https://mycpa.cpa.state.tx.us/coa/</a> to locate the mailing address & taxpayer number.
      </p>

      <div v-for="(registration) in registrations" :key="registration.id" class="registration-details">
        <p>Company Name: {{ registration.legal_name }}</p>

        <div v-for="(value, key) in registration" :key="key">
          <template v-if="!['id', 'legal_name'].includes(key.toLowerCase())">
            <p v-if="value">{{ formatLabel(key) }}: {{ value }}</p>
            <p v-else>
              {{ formatLabel(key) }}:
              <input :id="registration.id" :value="registration[key]" :placeholder="formatLabel(key)" @input="updateRegistrations(registration.id, key, $event.target.value)">
            </p>
          </template>
        </div>
      </div>
    </div>

    <div class="text-center">
      <b-button variant="secondary" class="mr-2" @click="logRemindMeLaterResponse()">
        Remind me later
      </b-button>
      <b-button class="btn btn-primary text-nowrap" type="submit" @click="saveHandler()">
        Save
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import axiosClient from '@/http'
import { createOrFindClientInteractionLog } from "@/common/modules/clientInteractionLog";

export default {
  name: 'RegistrationInfoNotificationModal',
  props: {
    registrations: Array,
  },
  data() {
    return {
      companyNames: [],
      missingRegistrations: [],
      updatedRegistrations: [],
    }
  },
  async mounted() {
    this.companyNames = this.registrations.map(r => r.company_name)
    this.missingRegistrations = this.registrations.map(r => r.registration)
    this.$refs['notification-modal'].show()
  },
  methods: {
    async saveHandler(_e) {
      await this.updateTaxpayerAndWebfileNumber()
      this.hidePopup()
    },
    async updateTaxpayerAndWebfileNumber() {
      await axiosClient.post(`/client/registrations/update_texas_registration`, { data: this.updatedRegistrations })
    },
    async logRemindMeLaterResponse() {
      await createOrFindClientInteractionLog({
        category: 'registrations',
        subCategory: 'remind-me-later',
        objectId: this.registrations[0].id,
        objectTable: 'Registration',
        companyId: this.registrations[0].company_id,
      })
      this.hidePopup()
    },
    hidePopup(){
      this.$refs['notification-modal'].hide()
    },
    updateRegistrations(registration_id, key, value) {
      let prevRegistration = this.updatedRegistrations.find(el => el.id === registration_id)
      if (prevRegistration) {
        prevRegistration[key] = value
      } else {
        prevRegistration = {}
        prevRegistration['id'] = registration_id
        prevRegistration[key] = value
        this.updatedRegistrations.push(prevRegistration)
      }
    },
    formatLabel(key) {
      // Replace underscores with spaces and capitalize the first letter of each word
      return key.replace(/_/g, ' ')
        .replace(/\b\w/g, firstLetter => firstLetter.toUpperCase())
    },
  },
}
</script>

<style scoped>
.registration-details {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  background-color: beige;
}
</style>
